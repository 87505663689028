import React, { Fragment, useEffect } from "react";
import clsx from "clsx";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";


export const batchFields = [
  "id",
  { barcodes: ["id", "code"] }
];

export const shipmentFields = [
  {
    data: [
      "code",
      "recipientName",
      "recipientAddress",
      {
        recipientZone: ["name"],
      },
      {
        recipientSubzone: ["name"],
      },
    ],
  },
];

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
  printSticky: `${PREFIX}-printSticky`,
  stickyCode: `${PREFIX}-stickyCode`,
  stickyRecipientName: `${PREFIX}-stickyRecipientName`,
  stickyDestination: `${PREFIX}-stickyDestination`,
  stickyRecipientAddress: `${PREFIX}-stickyRecipientAddress`,
  stickyTotalAmount: `${PREFIX}-stickyTotalAmount`,
  centerContent: `${PREFIX}-centerContent`,
  barcode: `${PREFIX}-barcode`,
};
export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.printSticky}`]: {
     
    pageBreakInside: "avoid",
    maxWidth: "5cm",
    maxHeight: "4cm",
    overflow: "hidden",
    fontSize: 11,
    color: "black",
  },

  [`& .${classes.stickyCode}`]: {
    height: "calc(100% * (1/4))",
    overflow: "hidden",
    fontSize: "30px !important",
  },

  [`& .${classes.stickyRecipientName}`]: {
    height: "calc(100% * (0.75/4))",
    overflow: "hidden",
    whiteSpace: "pre",
  },

  [`& .${classes.stickyDestination}`]: {
    height: "calc(100% * (0.75/4))",
    overflow: "hidden",
    whiteSpace: "pre",
  },

  [`& .${classes.stickyRecipientAddress}`]: {
    height: "calc(100% * (1.5/4))",
    overflow: "hidden",
    WebkitLineClamp: 3,
  },
  [`& .${classes.stickyTotalAmount}`]: {
    height: "calc(100% * (1.5/4))",
    overflow: "hidden",
  },
  [`& .${classes.centerContent}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 48,
  },
}));

function createDynamicStyles() {
  const style = document.createElement('style');
  style.type = 'text/css';

  const styles = `
  @page {
      size: 5cm 4cm; 
      margin: 1mm !important;
      overflow: hidden;
  }
  * {
          color: black;
  }
  body {
          background: #fff !important
  }
  `;

  if (style.styleSheet) {
    style.styleSheet.cssText = styles;
  } else {
    style.appendChild(document.createTextNode(styles));
  }

  document.head.appendChild(style);
}

const Sticker_5X4 = (props) => {
  const {
    loading,
    validData,
    parsedData,
    isBatch,
  } = props

  const { t } = useTranslation()

  useEffect(() => {
    createDynamicStyles()

  }, [])

  return (
    <Fragment>
      {loading || (!loading && !validData) ? (
        <>
          <EmptyTableMessage
            loading={loading}
            message={t("noShipmentWithCode")}
          />
        </>
      ) : (
        parsedData.map((shipment, index) => (
          <Root key={index}>
            <div
              className={clsx({
                [classes.printSticky]: !loading && validData,
              })}
              style={{ height: "100%", lineHeight: "1.4" }}
            >
              <div
                className={clsx(
                  classes.barcode,
                  classes.centerContent,
                  classes.stickyCode
                )}
              >
                *{shipment.code}*
              </div>
              <div className={classes.stickyRecipientName}>
                - {shipment?.recipientName}
              </div>
              <div className={classes.stickyDestination}>
                -{!isBatch && " " +
                  shipment?.recipientZone?.name +
                  " - " +
                  shipment?.recipientSubzone?.name}
              </div>
              <div
                className={clsx(
                  classes.stickyRecipientAddress,
                  "recipient-address"
                )}
              >
                - {shipment.recipientAddress}
              </div>
              <div
                className={clsx(
                  classes.stickyTotalAmount,
                  "recipient-address"
                )}
              >
                - {t("cod")}{" : "} {shipment.totalAmount}
              </div>
            </div>
          </Root>
        ))
      )}
    </Fragment>
  );
};

export default Sticker_5X4;
